import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { priceWithFallback } from '../../../Utils/NumberUtils'
import { basicPluralize } from '../../../Utils/StringUtils'

const SplitLayoutMapSelectedListing = ({ listing }) => {
  const openHouseCount = listing.open_houses?.total_count || 0
  const photoUrl = listing.thumbnail_image_url
  const statusText =
    openHouseCount > 0
      ? `Upcoming Open ${basicPluralize('House', openHouseCount)}`.toUpperCase()
      : listing.display_status.toUpperCase()

  return (
    <Link
      to={{ pathname: `/listings/${listing.slug}`, state: { modal: false } }}
      style={{ textDecoration: 'none' }}
    >
      <Card
        sx={{
          position: 'fixed',
          bottom: '40px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1001,
          width: '96%',
          height: '260px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
          backgroundColor: '#FFF',
          borderRadius: '8px',
          overflow: 'hidden',
          '&:focus, &:hover': { outline: 'none', backgroundColor: '#FFF' },
        }}
      >
        <CardMedia
          component="img"
          height="75%"
          image={photoUrl}
          sx={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
        />
        <CardContent
          sx={{
            width: '100%',
            backgroundColor: '#fff',
            borderTop: '1px solid rgba(0, 0, 0, 0.1)',
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
            p: 1,
          }}
        >
          <Typography variant="body2">{statusText}</Typography>
          <Typography variant="h5">{priceWithFallback(listing.display_price)}</Typography>
        </CardContent>
      </Card>
    </Link>
  )
}

export default SplitLayoutMapSelectedListing
