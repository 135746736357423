import { gql } from '@apollo/client'
import { useState, useEffect, useRef } from 'react'
import { Box, CardContent, Divider, Stack, Typography } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import AreaReportMap from './AreaReportMap'
import ListingCardsContainer from './ListingCardsContainer'
import MetricWidget from './MetricWidget'
import { useVerticalScrollWithShadow, useHorizontalScrollWithShadow } from '../../../../CustomHooks/useScrollWithShadowHook'
import RsCard from '../../../../Common/UIComponents/Card/RsCard'

export const ListingsFragment = gql`
  fragment ListingItem on Property {
    id
    address
    beds_total
    baths_total
    postal_code
    city
    display_price
    encoded_id
    mls_logo
    mls_single_disclaimer
    display_status
    slug
    structure_sqft
    sold_date
    display_rules {
      show_logo_on_results
    }
    photos(first: 1) {
      edges {
        node {
          photo_url
          id
        }
      }
    }
  }
`

export const AREA_REPORT_FIELDS = gql`
  ${ListingsFragment}
  fragment AreaReportFields on HomeReport {
    latitude
    longitude
    avg_list_price
    avg_sold_price
    avg_listing_price_per_sqft
    avg_sold_price_per_sqft
    recently_sold_listings_total_count
    recently_updated_listings_total_count
    recently_sold_listings {
      ...ListingItem
      latitude
      longitude
    }
    recently_updated_listings {
      ...ListingItem
      latitude
      longitude
    }
    comparable_listings_disclaimer
  }
`

const AreaReport = ({ title, testId, metrics, latitude, longitude, listings, previewMode, comparableListingsDisclaimer, loading }) => {
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))
  const isSmallerScreen = useMediaQuery('(max-width: 1080px)') // set to avoid only one metrics wrapping to the next line
  const [height, setHeight] = useState(0)
  const mapContentRef = useRef(null)
  const [selectedListingId, setSelectedListingId] = useState(null)
  const metricStackStyles = { my: 2, px: isSmallerScreen ? 2 : 6, maxWidth: '1280px' }
  const divider = <Divider orientation="vertical" />
  const { verticalBoxShadow, onVerticalScrollHandler } = useVerticalScrollWithShadow()
  const { horizontalBoxShadow, onHorizontalScrollHandler } = useHorizontalScrollWithShadow()

  useEffect(() => {
    if (mapContentRef.current) {
      setHeight(mapContentRef.current.clientHeight)
    }
  })

  if (listings.length === 0) {
    return (
      <RsCard title={title} subheader="Market stats based upon listings similar to your home.">
        <CardContent sx={{ textAlign: 'center' }}>
          <HomeIcon fontSize="large" sx={{ color: theme.palette.grey[400] }} />
          <Typography sx={{ color: theme.palette.grey[400] }}>No listings available</Typography>
        </CardContent>
      </RsCard>
    )
  }

  // Filter out the metrics which are 0
  const filteredMetrics = metrics.filter(metric => metric.value !== 0)

  return (
    <RsCard title={title} subheader="Market stats based upon listings similar to your home." className={`${testId}-card`}>
      <Box sx={{ width: '100%' }}>
        <Stack direction="row" justifyContent="space-around" flexWrap="wrap" sx={metricStackStyles} divider={divider} spacing={1}>
          {filteredMetrics.map(({ key, title, value }) => (
            <Box key={key} data-testid={`${key}-metric`}>
              <MetricWidget title={title} value={value} loading={loading} />
            </Box>
          ))}
        </Stack>
      </Box>
      <Stack spacing={isSmallerThanMd ? 0 : 2} direction={isSmallerThanMd ? 'column' : 'row'} sx={{ mb: 2 }} data-testid={testId}>
        <Box sx={isSmallerThanMd ? { px: 2 } : { pl: 2 }} ref={mapContentRef}>
          <AreaReportMap latitude={latitude} longitude={longitude} listingsToDisplay={listings} selectedListingId={selectedListingId} setSelectedListingId={setSelectedListingId} previewMode={previewMode} />
          <Typography variant="subtitle2" component="p" pt={2} textAlign={isSmallerThanMd ? 'center' : 'left'} sx={{ maxWidth: 'fit-content' }}>
            {comparableListingsDisclaimer}
          </Typography>
        </Box>
        <Box flexShrink={1} sx={{ position: 'relative' }} onScroll={isSmallerThanMd ? onHorizontalScrollHandler : onVerticalScrollHandler}>
          <Box sx={{ position: 'absolute', top: 0, bottom: 0, height: '100%', width: '100%', boxShadow: isSmallerThanMd ? horizontalBoxShadow : verticalBoxShadow, pointerEvents: 'none', zIndex: 1 }} />
          <ListingCardsContainer listingsToDisplay={listings} selectedListingId={selectedListingId} previewMode={previewMode} height={height} />
        </Box>
      </Stack>
    </RsCard>
  )
}

export default AreaReport
