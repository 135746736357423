import { Button, CircularProgress, Link, Tooltip, Typography } from '@mui/material'
import { gql, useMutation } from '@apollo/client'
import HelpIcon from '@mui/icons-material/Help'
import { useSnackbar } from 'notistack'
import { Link as RouterLink } from 'react-router-dom'
import { useContext } from 'react'
import useMutationSnackbarMessages from '@/components/CustomHooks/MutationSnackbarHooks'
import { CurrentUserContext } from '@/components/Homebuyer/Contexts/CurrentUserContext'

export const REQUEST_PRICING_INFO = gql`
  mutation requestPricingInfo($input: CreateConversationMessageMutationInput!) {
    create_conversation_message(input: $input) {
      errors {
        key
        message
      }
      conversation {
        id
      }
    }
  }
`

const RequestInfoButton = ({ agentId, propertyId, propertyAddress }) => {
  const { loggedIn, showSignUp } = useContext(CurrentUserContext)
  const mutationSnackbarMessages = useMutationSnackbarMessages({
    dataAttribute: 'create_conversation_message',
    customSuccessMessage: (
      <Typography>
        Your request has been sent to your agent.{' '}
        <Link color="info" href="/homesearch/conversations">
          View message
        </Link>
      </Typography>
    ),
  })

  const [requestPricingInfo, { data, loading }] = useMutation(REQUEST_PRICING_INFO, {
    variables: {
      input: {
        attachment_ids: [propertyId],
        body: `Cost detail request for ${propertyAddress}`,
        recipient: agentId,
      },
    },
    ...mutationSnackbarMessages,
  })

  const handleClick = () => {
    if (loggedIn) {
      requestPricingInfo()
    } else {
      showSignUp()
    }
  }

  return (
    <Tooltip title="Send a request to your agent for additional information regarding the cost breakdown for this property">
      <Button
        onClick={handleClick}
        disabled={loading}
        variant="contained"
        startIcon={loading && <CircularProgress size={20} />}
      >
        Request cost details from your agent
      </Button>
    </Tooltip>
  )
}

export default RequestInfoButton
