import React, { useState, forwardRef } from 'react'
import SplitCardsContainer from './SplitLayout/SplitCardsContainer'
import SplitLayoutMap from './SplitLayout/SplitLayoutMap'
import MobileSearchHeader from './SplitLayout/MobileSearchHeader'
import MapToggleButton, { VIEW_TYPE } from './SplitLayout/MapToggleButton'
import { gql } from '@apollo/client'
import styles from './SplitLayout.module.scss'
import { CurrentUserConsumer } from './../Contexts/CurrentUserContext'
import { SplitLayoutConsumer } from './../Contexts/SplitLayoutContext'
import { withRouter } from 'react-router-dom'
import MobileSearchFilters from '../Pages/Search/MobileSearchFilters'
import CountAndSortHeader from './SplitLayout/CountAndSortHeader'
import { TabletAndDesktop, Mobile } from '../../Common/ResponsiveComponents'

export const listingSearchFragment = gql`
  fragment ListingSearch on Searcher {
    listings {
      id
      address
      beds_total
      baths_total
      city
      display_price
      encoded_id
      latitude
      lot_sqft
      longitude
      mls_logo
      mls_number
      mls_single_disclaimer
      postal_code
      simple_listing_status
      display_status
      slug
      structure_sqft
      display_rules {
        show_logo_on_results
      }
      open_houses {
        total_count
      }
      verdict {
        id
        prose
      }
      thumbnail_image_url
    }
    total_count
    current_count
    geo_shapes {
      id
      name
      geo_json
    }
    geo_circles {
      radius
      coordinates
    }
  }
`

const SplitLayout = ({
  selectedListingId,
  setSelectedListing,
  history,
  mobileViewType,
  title,
  children,
}) => {
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const selectListing = (listing, isMobile = false) => {
    if (isMobile) {
      setSelectedListing(listing, true)
    } else {
      if (selectedListingId === listing.id) {
        history.push({ pathname: `/listings/${listing.slug}`, state: { modal: true } })
      } else {
        document.getElementById(`listing-card-${listing.id}`).scrollIntoView()
        setSelectedListing(listing)
      }
    }
  }

  const renderMobileView = () => {
    if (mobileViewType === VIEW_TYPE.map) {
      // Mobile Map View
      return (
        <div className={styles['map-container']}>
          <SplitLayoutMap
            selectListing={listing => selectListing(listing, true)}
            onMapClick={() => setSelectedListing(null, true)}
          />
          <MobileSearchFilters {...{ filterModalOpen, setFilterModalOpen }} />
          <MapToggleButton />
        </div>
      )
    } else {
      // Mobile List View
      return (
        <>
          <SplitCardsContainer selectListing={selectListing} />
          <MobileSearchFilters {...{ filterModalOpen, setFilterModalOpen }} />
          <MapToggleButton />
        </>
      )
    }
  }

  return (
    <div id="listings-layout">
      {/* Desktop View*/}
      <TabletAndDesktop>
        <div className={styles['list-cards-container']}>
          <div className={styles.header}>
            <div className="row">
              <div className="col">
                <h4 className="mb-0 mt-2 text-truncate">{title}</h4>
              </div>
              <div className="col-md-auto">{children}</div>
            </div>
          </div>
          <div className={styles.subheader}>
            <CountAndSortHeader />
          </div>
          <SplitCardsContainer selectListing={selectListing} />
        </div>
        <div className={styles['map-container']}>
          <SplitLayoutMap selectListing={selectListing} onMapClick={() => {}} />
        </div>
      </TabletAndDesktop>

      {/* Mobile View*/}
      <Mobile>
        <MobileSearchHeader {...{ setFilterModalOpen, setSelectedListing }} />
        {renderMobileView()}
      </Mobile>
    </div>
  )
}

const SplitLayoutWithConsumer = forwardRef((props, ref) => (
  <CurrentUserConsumer>
    {({ mobileViewType, viewType }) => (
      <SplitLayoutConsumer>
        {({ loading, setSelectedListing, selectedListingId, title }) => (
          <SplitLayout
            {...{
              loading,
              setSelectedListing,
              selectedListingId,
              viewType,
              mobileViewType,
              title,
              ref,
              ...props,
            }}
          />
        )}
      </SplitLayoutConsumer>
    )}
  </CurrentUserConsumer>
))

export default withRouter(SplitLayoutWithConsumer)
