import { useContext, useState } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { Helmet } from 'react-helmet'
import LazyLoad from 'react-lazyload'
import { Typography, Box, Stack, Grid, Container, useTheme, useMediaQuery } from '@mui/material'
import Chat from './ListingDetail/Chat'
import SignUp from './ListingDetail/SignUp'
import Header, { LISTING_HEADER_FRAGMENT } from './Listing/Header'
import MainDetails, { LISTING_MAIN_DETAILS_FRAGMENT } from './Listing/MainDetails'
import Map, { LISTING_MAP_FRAGMENT } from './Listing/Map'
import FullDetails, { LISTING_FULL_DETAILS_FRAGMENT } from './Listing/FullDetails'
import Highlights, { LISTING_HIGHLIGHTS_FRAGMENT } from './Listing/Highlights'
import Feedback, { LISTING_FEEDBACK_FRAGMENT } from './Listing/Feedback'
import Schools from './Listing/Schools'
import History, { LISTING_HISTORY_FRAGMENT } from './Listing/History'
import AgentRemarks, { AGENT_REMARKS_FRAGMENT } from './Listing/AgentRemarks'
import AgentChatWidget from './ListingDetail/AgentChatWidget'
import BrokerageChatWidget from './ListingDetail/BrokerageChatWidget'
import OpenHouses, { LISTING_OPEN_HOUSES_FRAGMENT } from './Listing/OpenHouses'
import ListingPhotos, { LISTING_PHOTOS_FRAGMENT } from '../../Common/ListingDetail/ListingPhotos'
import { CurrentUserContext } from '../Contexts/CurrentUserContext'
import CollectionControls from './Listing/CollectionControls'
import { reportError } from '../../Common/ErrorCapture'
import SignUpModal from '../Layout/SignUpModal'
import ClimateScore from './Listing/ClimateScore'
import Unauthorized from './ListingDetail/Unauthorized'
import Footer from './Listing/Footer'
import MortgageCalculator, {
  MORTGAGE_CALCULATOR_FRAGMENT,
} from '@/components/Homebuyer/Common/MortgageCalculator'
import { AGENT_FRAGMENT } from '../../Common/QuickMessageCard'
import LoadingListing from '@/components/Homebuyer/Pages/Listing/LoadingListing'
import UnavailableListing from '@/components/Homebuyer/Pages/Listing/UnavailableListing'
import DesktopPhotoCarousel from '../../Common/ListingDetail/Photos/DesktopPhotoCarousel'
import DesktopPhotoList from '../../Common/ListingDetail/Photos/PhotoList/DesktopPhotoList'
import { PhotoModalState } from '../../Common/Constants/ListingDetailContants'

export const LISTING_ATTRIBUTION_FRAGMENT = gql`
  fragment listingAttributionUnderPhotoFragment on Property {
    id
    listing_agent_attribution
    display_rules {
      show_attribution_under_photo
    }
  }
`

const LISTING_QUERY = gql`
  query clientLDPListingQuery($id: ID!) {
    current_user {
      id
      ...mortgageCalculatorUserFragment
      can_view_cost_calculator
      agent {
        ...AgentFragment
      }
      property_by_id(id: $id) {
        ...listingHeaderFragment
        ...listingMainDetailsFragment
        ...listingOpenHousesFragment
        ...listingFullDetailsFragment
        ...mortgageCalculatorPropertyFragment
        ...listingAttributionUnderPhotoFragment
        ...listingHighlightsFragment
        ...listingHistoryFragment
        ...listingMapFragment
        ...listingFeedbackFragment
        ...agentRemarksFragment
        ...listingPhotosFragment
        id
        encoded_id
        slug
        mls_number
        mls_name
        mls_logo
        mls_display_name
        mls_disclaimer
        messages {
          ...Message
        }
        user_interactions {
          id
          conversation {
            id
          }
        }
        listing_agent {
          license_number
          name
        }
        listing_office {
          name
        }
        colisting_agent {
          name
        }
        selling_agent {
          name
        }
        selling_office {
          name
        }
        updated_at
        feed_updated_at
        address
        city
        state
        is_exclusive
        idx_attribution_contact
        listing_agent_attribution
        co_listing_agent_attribution
        selling_agent_attribution
        co_selling_agent_attribution
        unauthorized
        verdict {
          id
          prose
          rating
        }
      }
    }
  }
  ${Chat.fragments.messageFragment}
  ${AGENT_FRAGMENT}
  ${LISTING_HEADER_FRAGMENT}
  ${LISTING_MAIN_DETAILS_FRAGMENT}
  ${LISTING_OPEN_HOUSES_FRAGMENT}
  ${MORTGAGE_CALCULATOR_FRAGMENT}
  ${LISTING_FULL_DETAILS_FRAGMENT}
  ${LISTING_ATTRIBUTION_FRAGMENT}
  ${LISTING_HIGHLIGHTS_FRAGMENT}
  ${LISTING_HISTORY_FRAGMENT}
  ${LISTING_MAP_FRAGMENT}
  ${LISTING_FEEDBACK_FRAGMENT}
  ${AGENT_REMARKS_FRAGMENT}
  ${LISTING_PHOTOS_FRAGMENT}
`

const RECORD_LISTING_VIEW_MUTATION = gql`
  mutation recordPropertyView($data: RecordPropertyViewInput!) {
    record_property_view(input: $data) {
      property {
        id
      }
    }
  }
`

const ListingDetail = ({ match, isModal, onCloseModal }) => {
  const { loggedIn, showingSignUp, displayRules, agent } = useContext(CurrentUserContext)
  const [photoModalState, setPhotoModalState] = useState(PhotoModalState.NONE)
  const [photoIndex, setPhotoIndex] = useState(0)
  const propertyId = match.params.id
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = !isMobile

  const [recordListingView] = useMutation(RECORD_LISTING_VIEW_MUTATION)

  const { data, loading } = useQuery(LISTING_QUERY, {
    variables: {
      id: propertyId,
    },
    onError: reportError,
    onCompleted: queryData => {
      if (
        loggedIn &&
        !agent.agent_logged_in &&
        queryData?.current_user?.property_by_id?.encoded_id
      ) {
        recordListingView({
          variables: {
            data: {
              property_id: queryData?.current_user?.property_by_id?.encoded_id,
              context: 'ldp',
            },
          },
        })
      }
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const {
    id: userId,
    mortgage_rate_basis_points: mortgageRateBasisPoints,
    mortgage_type: mortgageType,
    down_payment_type: downPaymentType,
    down_payment_amount: downPaymentAmount,
  } = data?.current_user || {}

  const listing = data?.current_user?.property_by_id || {}
  const {
    unauthorized,
    is_exclusive: isExclusive,
    address,
    city,
    photos,
    agent_comment: agentComment,
    open_houses: openHouses,
    encoded_id: encodedId,
    latitude,
    longitude,
    details,
    messages,
    user_interactions: userInteractions,
    property_histories: propertyHistories,
    simple_listing_status: simpleListingStatus,
    days_on_site: daysOnSite,
    sold_date: soldDate,
    beds_total: bedsTotal,
    baths_total: bathsTotal,
    structure_sqft: structureSqft,
    sold_price: soldPrice,
    simple_type: propertyType,
    list_price: listPrice,
    listing_agent_attribution: listingAgentAttribution,
    display_rules: listingDisplayRules,
    display_status: displayStatus,
    combined_display_status: combinedDisplayStatus,
    state,
    postal_code: postalCode,
    lot_size_value: lotSizeValue,
    lot_size_units: lotSizeUnits,
    slug,
    virtual_tour_urls: virtualTourUrls,
    mls_logo: mlsLogo,
    mls_display_name: mlsDisplayName,
    public_remarks: publicRemarks,
    short_details: shortDetails,
    selling_office: sellingOffice,
    tags,
  } = listing || {}

  const showSignUpModal =
    isExclusive && !loggedIn && !showingSignUp && displayRules.exclusives_protected
  const showClimateScoreWidget = !displayRules.suppress_climate_check_widget
  const showOpenHouses = openHouses?.total_count > 0
  const showListingAttributionUnderPhoto =
    listingDisplayRules?.show_attribution_under_photo && listingAgentAttribution
  const showHighlights = !!tags?.length
  const showFeedback = loggedIn && userInteractions

  if (loading) {
    return <LoadingListing />
  }

  if (photoModalState === PhotoModalState.PHOTO_LIST) {
    return (
      <DesktopPhotoList
        photos={photos}
        onCloseModal={() => {
          setPhotoModalState(PhotoModalState.NONE)
          onCloseModal?.()
        }}
        goBack={() => {
          setPhotoIndex(0)
          setPhotoModalState(PhotoModalState.NONE)
        }}
        address={address}
        photoIndex={photoIndex}
        setPhotoIndex={setPhotoIndex}
        onClickListPhoto={index => {
          setPhotoIndex(index)
          setPhotoModalState(PhotoModalState.PHOTO_CAROUSEL)
        }}
      />
    )
  }

  if (photoModalState === PhotoModalState.PHOTO_CAROUSEL) {
    return (
      <DesktopPhotoCarousel
        photos={photos}
        photoIndex={photoIndex}
        onCloseModal={() => {
          setPhotoIndex(0)
          setPhotoModalState(PhotoModalState.NONE)
          onCloseModal?.()
        }}
        goBack={() => setPhotoModalState(PhotoModalState.PHOTO_LIST)}
        address={address}
      />
    )
  }

  if (unauthorized) {
    return <Unauthorized listing={listing} />
  }

  if (!listing) {
    return <UnavailableListing />
  }

  const items = {
    left: [
      {
        name: 'agent_remarks',
        show: agentComment,
        mobileOrder: 1,
        component: <AgentRemarks comment={agentComment?.comment} />,
      },
      {
        name: 'main_details',
        show: true,
        mobileOrder: 6,
        component: (
          <MainDetails
            virtualTourUrls={virtualTourUrls?.filter(Boolean) || []}
            isExclusive={isExclusive}
            simpleListingStatus={simpleListingStatus}
            mlsLogo={mlsLogo}
            mlsDisplayName={mlsDisplayName}
            publicRemarks={publicRemarks}
            shortDetails={shortDetails}
            listingAgentAttribution={listingAgentAttribution}
            sellingOfficeName={sellingOffice?.name}
          />
        ),
      },
      {
        name: 'open_houses',
        show: showOpenHouses,
        mobileOrder: 5,
        component: <OpenHouses openHouses={openHouses} listingId={encodedId} address={address} />,
      },
      {
        name: 'map',
        show: true,
        mobileOrder: 7,
        component: <Map latitude={latitude} longitude={longitude} />,
      },
      {
        name: 'full_details',
        show: true,
        mobileOrder: 9,
        component: <FullDetails fullDetailsProp={details} />,
      },
    ].filter(i => i.show),
    right: [
      {
        name: 'chat',
        show: loggedIn,
        mobileOrder: 2,
        component: (
          <Chat messages={messages} listing={listing} userId={userId} disableMarginBottom />
        ),
      },
      {
        name: 'brokerage_chat_widget',
        show: loggedIn && agent?.use_legacy_messenger,
        mobileOrder: 3,
        component: (
          <BrokerageChatWidget
            listingConversation={userInteractions?.conversation}
            userId={userId}
            listingId={listing.id}
          />
        ),
      },
      {
        name: 'agent_chat_widget',
        show: loggedIn && !agent?.use_legacy_messenger,
        mobileOrder: 3,
        component: (
          <AgentChatWidget
            listingConversation={userInteractions?.conversation}
            agent={data?.current_user?.agent}
            listingId={listing.id}
            listingEncodedId={encodedId}
            listingStatus={simpleListingStatus}
            disableBoxShadow
          />
        ),
      },
      {
        name: 'sign_up',
        show: !loggedIn,
        mobileOrder: 4,
        component: <SignUp listing={listing} />,
      },
      {
        name: 'feedback',
        show: showFeedback,
        mobileOrder: 8,
        component: (
          <Feedback listingId={encodedId} address={address} feedback={userInteractions?.feedback} />
        ),
      },
      {
        name: 'mortgage_calculator',
        show: data.current_user.can_view_cost_calculator,
        mobileOrder: 13,
        component: (
          <MortgageCalculator
            userId={data.current_user.id}
            agentId={data.current_user.agent?.id}
            propertyId={encodedId}
            address={address}
            initialListingPrice={listing.list_price}
            initialInterestRate={
              mortgageRateBasisPoints ? mortgageRateBasisPoints / 100.0 : undefined
            }
            mortgageOptions={data.current_user.current_mortgage_rates}
            initialMortgageType={mortgageType || undefined}
            initialDownPaymentType={downPaymentType || undefined}
            initialDownPaymentAmount={downPaymentAmount || undefined}
            propertyType={propertyType}
            simpleListingStatus={simpleListingStatus}
          />
        ),
      },
      {
        name: 'highlights',
        show: showHighlights,
        mobileOrder: null, // hidden on mobile
        component: (
          <LazyLoad height={300}>
            <Highlights listingTags={tags} address={address} />
          </LazyLoad>
        ),
      },
      {
        name: 'schools',
        show: true,
        mobileOrder: 10,
        component: (
          <LazyLoad height={300}>
            <Schools propertyId={propertyId} />
          </LazyLoad>
        ),
      },
      {
        name: 'history',
        show: true,
        mobileOrder: 11,
        component: <History listingHistory={propertyHistories} />,
      },
      {
        name: 'climate_score',
        show: showClimateScoreWidget,
        mobileOrder: 12,
        component: (
          <LazyLoad height={300}>
            <ClimateScore agentView={false} propertyId={propertyId} />
          </LazyLoad>
        ),
      },
    ].filter(i => i.show),
  }

  const mobileItems = [...items.left, ...items.right]
    .filter(item => item.mobileOrder)
    .sort((a, b) => a.mobileOrder - b.mobileOrder)

  return (
    <Box
      key={propertyId}
      id="listing-detail"
      sx={theme => ({
        backgroundColor: theme.colors.alpha.black[5],
      })}
    >
      <Helmet title={`${address}, ${city}`} />
      <CollectionControls
        isModal={isModal}
        id={propertyId}
        onCloseModal={onCloseModal}
        addressProp={address}
      />

      <Box sx={theme => ({ backgroundColor: theme.colors.alpha.white[100] })}>
        <Container sx={{ py: isMobile ? 2 : 3 }}>
          <ListingPhotos
            photos={photos}
            photoIndex={photoIndex}
            setPhotoIndex={setPhotoIndex}
            openDesktopPhotoList={() => setPhotoModalState(PhotoModalState.PHOTO_LIST)}
          />

          {showListingAttributionUnderPhoto && (
            <Box sx={{ position: 'relative' }}>
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="right"
                lineHeight="1"
                sx={{
                  position: 'absolute',
                  right: 0,
                  pt: 0.25,
                }}
              >
                Listed by {listingAgentAttribution}
              </Typography>
            </Box>
          )}
          <Box pt={3}>
            <Header
              simpleListingStatus={simpleListingStatus}
              daysOnSite={daysOnSite}
              soldDate={soldDate}
              bedsTotal={bedsTotal}
              bathsTotal={bathsTotal}
              structureSqft={structureSqft}
              soldPrice={soldPrice}
              listPrice={listPrice}
              listingDisplayRules={listingDisplayRules}
              displayStatus={displayStatus}
              combinedDisplayStatus={combinedDisplayStatus}
              address={address}
              city={city}
              state={state}
              postalCode={postalCode}
              lotSizeValue={lotSizeValue}
              lotSizeUnits={lotSizeUnits}
              slug={slug}
            />
          </Box>
        </Container>
      </Box>

      <Container sx={{ py: isMobile ? 2 : 3 }}>
        {isDesktop && (
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Stack direction="column" spacing={3}>
                {items.left.map(item => (
                  <Box key={item.name}>{item.component}</Box>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={5}>
              <Stack direction="column" spacing={3}>
                {items.right.map(item => (
                  <Box key={item.name}>{item.component}</Box>
                ))}
              </Stack>
            </Grid>
          </Grid>
        )}

        {isMobile && (
          <Stack direction="column" spacing={2}>
            {mobileItems.map(item => (
              <Box key={item.name}>{item.component}</Box>
            ))}
          </Stack>
        )}
      </Container>

      <Footer listing={listing} />
      {showSignUpModal && (
        <SignUpModal
          showingSignUp
          closeSignUp={() => {}}
          title="Sign Up"
          subtitle="You must be logged in to view this exclusive listing"
        />
      )}
    </Box>
  )
}

export default ListingDetail
