import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IconButton, Button, Typography, Box, useTheme, useMediaQuery, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { CollectionContext } from '../../Contexts/CollectionContext'

const PrevNextButton = ({ title, subtitle, ...btnProps }) => (
  <Button {...btnProps} size="small">
    <Box>
      <Typography
        fontWeight="600"
        lineHeight="1"
        fontSize="15px"
        color={btnProps.disabled ? 'text.disabled' : 'text.primary'}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="body2"
          color="text.secondary"
          lineHeight="1"
          fontWeight="500"
          pt={0.25}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  </Button>
)

const CollectionControls = ({ id, isModal, onCloseModal, addressProp }) => {
  const theme = useTheme()
  const isNotMobile = useMediaQuery(theme.breakpoints.not('xs'))

  const { setCurrentListing, collection, previousListing, nextListing, currentListing } =
    useContext(CollectionContext)

  useEffect(() => {
    setCurrentListing(id)
  }, [id])

  const hasPreviousListing = !!previousListing
  const hasNextListing = !!nextListing

  const showCollectionView =
    currentListing &&
    [currentListing?.slug, currentListing?.id].includes(id) &&
    collection &&
    (hasPreviousListing || hasNextListing)

  const showSingleListingModalView = isModal && isNotMobile && addressProp

  if (showCollectionView) {
    return (
      <Box
        display="flex"
        alignItems="center"
        p={1}
        sx={{ height: 70, backgroundColor: theme => theme.colors.alpha.white[100] }}
      >
        <Box flex={1}>
          <PrevNextButton
            disabled={!hasPreviousListing}
            component={Link}
            to={{
              pathname: `/listings/${previousListing?.slug}`,
              state: { modal: isModal },
            }}
            startIcon={
              <ArrowBackIosIcon
                sx={theme => ({
                  color: theme.colors.alpha.black[hasPreviousListing ? 70 : 50],
                })}
              />
            }
            title="Prev Listing"
            subtitle={hasPreviousListing ? previousListing.address : null}
          />
        </Box>

        {isNotMobile && (
          <Box flex={1} sx={{ textAlign: 'center' }}>
            <Typography variant="h5" lineHeight="1.1" fontWeight="600" noWrap>
              {currentListing.address}
            </Typography>
          </Box>
        )}

        <Box flex={1} display="flex" justifyContent="flex-end">
          <Stack direction="row" spacing={isModal ? 1 : 0} alignItems="center">
            <PrevNextButton
              disabled={!hasNextListing}
              component={Link}
              to={{
                pathname: `/listings/${nextListing?.slug}`,
                state: { modal: isModal },
              }}
              endIcon={
                <ArrowForwardIosIcon
                  sx={theme => ({
                    color: theme.colors.alpha.black[hasNextListing ? 70 : 50],
                  })}
                />
              }
              title="Next Listing"
              subtitle={hasNextListing ? nextListing.address : null}
            />
            {isModal && (
              <Box>
                <IconButton
                  sx={{
                    color: theme => theme.colors.alpha.black[80],
                  }}
                  size="small"
                  onClick={onCloseModal}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
    )
  }

  if (showSingleListingModalView) {
    return (
      <Box
        display="flex"
        alignItems="center"
        p={1}
        sx={{ height: 70, backgroundColor: theme => theme.colors.alpha.white[100] }}
      >
        <Box flex={1} />

        <Box flex={1} sx={{ textAlign: 'center' }}>
          <Typography variant="h5" lineHeight="1" fontWeight="600">
            {addressProp}
          </Typography>
        </Box>

        <Box flex={1} display="flex" justifyContent="flex-end">
          <IconButton color="gray" size="small" onClick={onCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    )
  }

  return null
}

export default CollectionControls
