import { useState, useContext } from 'react'
import { CurrentUserContext } from '../../../Contexts/CurrentUserContext'
import { gql, useMutation } from '@apollo/client'
import { Box, Button, CircularProgress } from '@mui/material'
import { useSnackbar } from 'notistack'
import ErrorBoundarySimple from '../../../../Common/ErrorBoundarySimple'
import EastIcon from '@mui/icons-material/East'
import RegisterUserAndCreateHomeReportModal from './RegisterUserAndCreateHomeReportModal'
import AddressAutocomplete from '@/components/Common/AddressAutocomplete'

const CREATE_HOME_REPORT_MUTATION = gql`
  mutation clientCreateHomeReport($input: CreateClientHomeReportMutationInput!) {
    create_client_home_report(input: $input) {
      home_report {
        id
      }
      errors {
        key
        message
      }
    }
  }
`

const CreateHomeReportForm = ({}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { loggedIn } = useContext(CurrentUserContext)

  const [address, setAddress] = useState(null)
  const [postalCode, setPostalCode] = useState(null)
  const [stateAbbrev, setStateAbbrev] = useState(null)
  const [city, setCity] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [addressRequiredError, setAddressRequiredError] = useState(false)

  const handleCompleted = data => {
    const result = data.create_client_home_report
    if (result?.errors?.length) {
      const errorMessages = result.errors.map(e => `${e.key}: ${e.message}`).join(' ')
      enqueueSnackbar(`Errors: ${errorMessages}`, {
        variant: 'error',
        autoHideDuration: 3000,
      })
    }

    if (result.home_report) {
      const home_report = result.home_report

      enqueueSnackbar(`Home Alert successfully created!!`, {
        variant: 'success',
        autoHideDuration: 3000,
      })

      window.location.assign(`/homesearch/home-reports/${home_report.id}`)
    }
  }

  const [createHomeReport, { loading: createHomeReportLoading }] = useMutation(
    CREATE_HOME_REPORT_MUTATION,
    {
      onCompleted: handleCompleted,
    }
  )

  const handleSubmit = e => {
    e.preventDefault()

    if (!address && !city && !stateAbbrev && !postalCode) {
      setAddressRequiredError(true)
      return
    }

    let input = {
      address,
      city,
      state: stateAbbrev,
      postal_code: postalCode,
    }

    if (loggedIn) {
      createHomeReport({ variables: { input } })
    } else {
      setModalOpen(true)
    }
  }

  const handleCloseAndResetForm = () => {
    setAddress(null)
    setCity(null)
    setPostalCode(null)
    setStateAbbrev(null)
    setModalOpen(false)
  }

  const onAddressSelect = suggestion => {
    const fullAddress = [
      suggestion.address_line1,
      suggestion.address_line2,
      suggestion.address_line3,
    ]
      .filter(item => item !== '')
      .join(', ')

    setAddress(fullAddress)
    setCity(suggestion.address_level2)
    setPostalCode(suggestion.postcode)
    setStateAbbrev(suggestion.address_level1)
    setAddressRequiredError(false)
  }

  return (
    <ErrorBoundarySimple>
      <Box component="form" onSubmit={handleSubmit}>
        <AddressAutocomplete
          onSelect={onAddressSelect}
          hasMissingAddressError={addressRequiredError}
        />
        <Box pt={4}>
          <Button
            variant="contained"
            type="submit"
            size="large"
            disabled={createHomeReportLoading}
            fullWidth
            endIcon={
              createHomeReportLoading ? (
                <CircularProgress
                  sx={theme => ({ color: theme.colors.alpha.black[30] })}
                  size={15}
                />
              ) : (
                <EastIcon />
              )
            }
          >
            View Home Value
          </Button>
        </Box>
      </Box>
      <RegisterUserAndCreateHomeReportModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onCloseAndResetForm={handleCloseAndResetForm}
        addressInputs={{
          address,
          state: stateAbbrev,
          city,
          postal_code: postalCode,
        }}
      />
    </ErrorBoundarySimple>
  )
}

export default CreateHomeReportForm
