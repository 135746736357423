import React, { useState } from 'react'
import { useAddressAutofillCore, useSearchSession } from '@mapbox/search-js-react'
import { Autocomplete, CircularProgress, InputAdornment, Paper, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { debounce } from '@/components/Utils/DebounceUtils'
import * as Sentry from '@sentry/react'

const AddressAutocomplete = ({ onSelect, hasMissingAddressError }) => {
  const [loading, setLoading] = useState()
  const [hasNetworkError, setHasNetworkError] = useState()
  const { sessionToken } = useSearchSession()
  const autofill = useAddressAutofillCore({
    accessToken: window.mapbox_token,
  })

  const [suggestions, setSuggestions] = useState([])

  const onSearchInputChange = debounce((_event, newInputValue, reason) => {
    if (reason === 'input') {
      if (newInputValue?.length > 2) {
        getAddressSuggestions(newInputValue)
      }
    }
  }, 500)

  const getAddressSuggestions = async addressQuery => {
    try {
      const response = await autofill.suggest(addressQuery, {
        sessionToken: sessionToken.id,
      })
      setSuggestions(response.suggestions)
    } catch (error) {
      setHasNetworkError(true)
      setSuggestions([])
      Sentry.captureException(error)
    }
    setLoading(false)
  }

  const hasError = hasMissingAddressError || hasNetworkError
  let errorMsg = null
  if (hasMissingAddressError) {
    errorMsg = 'Please select an address'
  } else if (hasNetworkError) {
    errorMsg = 'Error searching for addresses. Please try again.'
  }

  return (
    <Autocomplete
      disableClearable
      blurOnSelect
      filterOptions={x => x}
      id="address-autocomplete"
      options={suggestions}
      data-1p-ignore={true}
      getOptionLabel={option => (typeof option === 'string' ? option : option.full_address)}
      PaperComponent={props => <Paper {...props} style={{ overflow: 'auto' }} />}
      noOptionsText={'No results found'}
      loadingText="Searching..."
      onChange={(e, selectedItem) => onSelect(selectedItem)}
      onInputChange={onSearchInputChange}
      renderInput={params => {
        return (
          <TextField
            {...params}
            sx={theme => ({
              backgroundColor: theme.colors.alpha.white[100],
            })}
            variant="outlined"
            onChange={() => {
              setLoading(true)
            }}
            label={null}
            className="mui-omnisearch-without-label"
            placeholder={'Enter your address'}
            error={hasError}
            helperText={errorMsg}
            inputProps={{
              // Turns off 1Password Autofill
              'data-1p-ignore': true,
              ...params.inputProps,
            }}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: '16px' }, // 16px (or greater) prevents iOS from auto-zooming
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={theme => ({ color: theme.colors.alpha.black[50] })}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress
                      sx={theme => ({ color: theme.colors.alpha.black[30], marginRight: 5 })}
                      size={15}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )
      }}
    />
  )
}

export default AddressAutocomplete
